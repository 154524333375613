@import "./fonts.css";
@import "./forms.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global styles */

@layer base {
  ::selection {
    @apply bg-primary-green/25;
  }

  html {
    -webkit-tap-highlight-color: transparent;
  }

  /* Change default focus outline to not out-of-place blue */
  a,
  button {
    @apply focus-visible:outline-current;
  }

  b,
  strong {
    @apply font-medium;
  }
}

/* Custom components */

@layer components {
  .styled-list {
    counter-reset: stepsCounter;

    li {
      @apply relative list-none pl-9;

      &::before {
        counter-increment: stepsCounter;
        content: counter(stepsCounter);
        @apply absolute left-0 top-0.5;
        @apply inline-flex h-6 w-6 items-center justify-center rounded-full bg-primary-green;
      }
    }
  }
}

/* Modified utlitites */

@layer utilities {
  /* Prose specific styles */
  .prose {
    --tw-prose-body: theme("colors.gray.800");
    @apply prose-headings:font-medium;
    @apply prose-a:text-mid-green;
    @apply prose-strong:font-medium;
  }
}
